<template>
  <div class="parentEnd">
    <div class="content">
      <div class="header">
        <i class="el-icon-arrow-left"></i>
        <span class="center">图谱英语</span>
        <p class="out" @click="loginout">退出登录</p>
      </div>
      <div class="logo">
        <img src="../../assets/images/parent/logo.png" alt="">
      </div>
      <!-- 下拉 / 时间选择 -->
      <div class="select" style="margin-top: 15px;" :style="xsList.length > 1 ? 'height:59vw;' : ''">
        <div class="data" :style="xsList.length > 1 ? 'height:53vw;' : ''">
          <div class="text">
            <p>
              <span> {{jzobj && jzobj.xsmc || '暂无学生'}} </span>
              <span> ( {{ jzobj && jzobj.bjmc || '暂无班级' }} ) </span>
            </p>
            <p class="btn" :style="bjSetlist.length === 0 ? 'display:none;' : ''" @click="childrenClick">更换孩子</p>
          </div>
        </div>
        <div class="selectbox">
          <el-form>
            <el-form-item label="班级" v-if="xsList.length > 1" size="mini">
              <el-select v-model="parentbjid" placeholder="请选择" @change="selectbjChange(parentbjid,jzobj.xsmc)">
                <el-option v-for="item in xsList" :key="item.bjid" :label="item.bjmc" :value="item.bjid" />
              </el-select>
            </el-form-item>
            <el-form-item label="集训" size="mini">
              <el-select v-model="parentjxid" placeholder="请选择" @change="selectChange(parentjxid)">
                <el-option v-for="item in jxList" :key="item.jxid" :label="item.jxmc" :value="item.jxid" />
              </el-select>
            </el-form-item>
          </el-form>
          <p v-show="this.jxobj.yxqq"> 日期范围：{{ yxqfw }} </p>
        </div>
        <div class="rope">
          <div>
            <img style="position:absolute;z-index:9;" src="../../assets/images/parent/img1.png" alt="">
          </div>
          <div>
            <img style="position:absolute;z-index:9;" src="../../assets/images/parent/img1.png" alt="">
          </div>
        </div>
      </div>
      <div class="information" v-if="!jxList.length" style="margin-top:20px;height:50vw;">
        <div class="data data-box" style="height:40vw;">
          <p class="tishi">提示！</p>
          <p class="nothing">您的孩子没有临时班级或者没有参加过集训，暂时没有数据展示，请参加完集训再来看吧！</p>
        </div>
      </div>
      <!-- 总体成绩 -->
      <div class="information" v-show="jxList.length" style="margin-top:15px;">
        <div class="img">
          <img src="../../assets/images/parent/block1.png" alt="">
          <p class="center">总体成绩</p>
        </div>
        <div class="data">
          <div class="databox">
            <div class="dataleft">
              <div>
                <img src="../../assets/images/parent/block2.png" alt="">
                <p>累计学习时长<span style="font-size:10px">（学习+测试）</span></p>
                <p>{{ currentData.ljxxscs || 0 + '分' }}</p>
              </div>
              <div>
                <img src="../../assets/images/parent/block3.png" alt="">
                <p>学习课文总数</p>
                <p>{{ currentData.xxkwzs || 0 }}篇</p>
              </div>
            </div>
            <div class="dataright">
              <img src="../../assets/images/parent/block4.png" alt="">
              <div class="rightbox">
                <p>整体情况</p>
                <div id="meter"></div>
                <!-- <p> {{ ybpval }} </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="rope">
          <div>
            <img style="position:absolute;z-index:9;" src="../../assets/images/parent/img1.png" alt="">
          </div>
          <div>
            <img style="position:absolute;z-index:9;" src="../../assets/images/parent/img1.png" alt="">
          </div>
        </div>
      </div>
      <!-- 学情分析 柱状图 - -->
      <div class="information" v-show="jxList.length" style="margin-top: 15px;">
        <div class="img">
          <img src="../../assets/images/parent/block1.png" alt="">
          <p class="center" style="width:;">学情分析</p>
        </div>
        <div class="data">
          <div id="column"></div>
        </div>
        <div class="rope">
          <div>
            <img style="position:absolute;z-index:9;" src="../../assets/images/parent/img1.png" alt="">
          </div>
          <div>
            <img style="position:absolute;z-index:9;" src="../../assets/images/parent/img1.png" alt="">
          </div>
        </div>
      </div>
      <!-- 进步曲线 -->
      <div class="information" v-show="jxList.length" style="margin-top: 15px;">
        <div class="img">
          <img src="../../assets/images/parent/block1.png" alt="">
          <p class="center" style="width:;">进步曲线</p>
        </div>
        <div class="data">
          <div id="broken"></div>
        </div>
      </div>
      <div class="mask" v-if="flag">
        <div class="title">选择孩子</div>
        <div class="maskbody">
          <div class="box" @click="choice(item)" v-for="item in bjSetlist" :key="item.xsid">
            {{ item.xsmc }}
          </div>
        </div>
      </div>
      <div class="shadow" @click="flag = false" v-if="flag"></div>
      <van-dialog @confirm="confirm" v-model:show="loginoutShow" title="提示" show-cancel-button>
        <span>
          <img src="../../assets/images/parent/sigh.png" alt="">
        </span>
        <span>是否退出登录?</span>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import {
  parentselect,//小孩下拉
  jxselect,//集训下拉
  parentXqfx,//学情分析
  parentJbqx,//进步曲线
} from "@/api/student";
import { onMounted, onBeforeUnmount, computed, reactive, toRefs, getCurrentInstance, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import { BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
]);
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    var brokeEcharts = reactive(null);
    var categoryEcharts = reactive(null);
    var gaugeEcharts = reactive(null);
    onBeforeUnmount(() => {
      brokeEcharts.dispose(); //销毁
      categoryEcharts.dispose(); //销毁
      gaugeEcharts.dispose(); //销毁
    })
    const data = reactive({
      tableDate: [],
      options: [
        { jxmc: "集训", jxid: 1 },
        { jxmc: "集训", jxid: 2 },
      ],
      parentjxid: "", // 集训id
      parentbjid: "", // 班级id
      times: [],
      show: false,
      date: "",
      minDate: new Date(2021, 0, 1),
      bjlist: [], // 保存家长数据
      jzobj: {}, // 显示在页面的家长数据
      jxList: [], // 保存集训数据
      jxobj: {}, // 当前选中的集训数据
      ztcjObj: {}, // 总体成绩对象
      currentData: [], // echarts数组
      ybpval: "良", // 仪表盘优良差
      flag: false, // 选择学生弹窗显示隐藏
      bjSetlist: [], // 去重之后的班级
      xsList: [], // 存储一个孩子的多个班级
      loginoutShow: false, // 登出弹窗
      // 仪表盘配置
      gaugeOption: {
        grid: {
          top: '10%',
        },
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.26999, "#FDDD60"],
                  [0.72999, "#7CFFB2"],
                  [1, "#3FA0FF"],
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -10,
              length: 2,
              lineStyle: {
                color: '#fff',
                width: 1
              }
            },
            splitLine: {
              distance: -10,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 1
              }
            },
            axisLabel: {
              show: false
              // color: 'auto',
              // distance: 10,
              // fontSize: 6,
              //  formatter: function (val) {
              //   return val+'%';
              // },
            },
            detail: {
              fontSize: 20,
              offsetCenter: [0, '120%'],
              valueAnimation: true,
              formatter: function (value) {
                let str = null
                if (value >= 73) {
                  str = '优'
                } else if (value < 73 && value >= 27) {
                  str = '良'
                } else {
                  str = '差'
                }
                return str
              },
              color: 'auto'
            },
            data: [
              {
                value: 0
              }
            ]
          }
        ]
      },
      // 柱状图配置
      categoryOption: {
        color: [
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#0974e4'
          },
          {
            offset: 1, color: '#95c7ff'
          }
          ]),
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#57c874'
          },
          {
            offset: 1, color: '#adffc5'
          }
          ]),
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#f8d542'
          },
          {
            offset: 1, color: '#ffecab'
          }]),
          new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0, color: '#f23f32'
          },
          {
            offset: 1, color: '#ffb5ad'
          },]),
        ],
        legend: {
          bottom: "12%",
          icon: "circle",
          formatter: function (name) {
            const show_text = name == 'threeXjzs' ? '三星' : name == 'twoXjzs' ? '二星' : name == 'oneXjzs' ? '一星' : name == 'zeroXjzs' ? '零星' : ''
            return show_text
          },
          data: ['dayTime', 'threeXjzs', 'twoXjzs', 'oneXjzs', 'zeroXjzs'],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (val) {
            let v = val[0].data
            const text =
              "<span style='font-weight:600;color:#666;line-height:30px'>" + v.product + "</span>" + "<br/>"
              + "<span style='color:#0974e4'>三&nbsp;&nbsp;星</span> " + "&nbsp;&nbsp;&nbsp;&nbsp;" + v.threeXjzs + "<br/>"
              + "<div'><span style='color:#57c874'>二&nbsp;&nbsp;星</span>" + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + v.twoXjzs + "</div><br/>"
              + "<div'><span style='color:#f8d542'>一&nbsp;&nbsp星</span>" + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + v.oneXjzs + "</div><br/>"
              + "<span style='color:#f23f32'>零&nbsp;&nbsp;星</span></span>" + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + v.zeroXjzs
            return text;
          },
        },
        dataset: {
          // dimensions: ["rq", "三星", "二星", "一星", '零星'],
          source: [],
        },

        xAxis: {
          type: "category",
        },
        yAxis: {
          type: "value",
          // min: 0,
          // max: 100,
          // axisLabel: {
          //   show: true,
          //   interval: "auto",
          //   formatter: "{value}%",
          // },
        },
        series: [
          {
            type: "bar",
            barWidth: '3vw',
            label: {
              //柱体上显示数值
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                fontSize: "0.6rem",
                color: "#333",
              },
              formatter: function (val) {
                return val.data.total
                  ? Math.round((val.data["熟词"] / 100) * val.data.total) + "个"
                  : "";
              },
            },
          },
          {
            type: "bar",
            barWidth: '3vw',
            label: {
              //柱体上显示数值
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                fontSize: "0.6rem",
                color: "#333",
              },
              formatter: function (val) {
                return val.data.total
                  ? Math.round((val.data["夹生词"] / 100) * val.data.total) +
                  "个"
                  : "";
              },
            },
          },
          {
            type: "bar",
            barWidth: '3vw',
            label: {
              //柱体上显示数值
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                fontSize: "0.6rem",
                color: "#333",
              },
              formatter: function (val) {
                return val.data.total
                  ? Math.round((val.data["生词"] / 100) * val.data.total) + "个"
                  : "";
              },
            },
          },
          {
            type: "bar",
            barWidth: '3vw',
            label: {
              //柱体上显示数值
              show: true, //开启显示
              position: "top", //在上方显示
              textStyle: {
                //数值样式
                fontSize: "0.6rem",
                color: "#333",
              },
              formatter: function (val) {
                return val.data.total
                  ? Math.round((val.data["生词"] / 100) * val.data.total) + "个"
                  : "";
              },
            },
          },
        ],
        grid: {
          show: false,
          top: "15%",
          left: "15%",
          right: "13%",
          bottom: "30%",
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            left: "9%",
            realtime: true,
            bottom: 15,
            height: 15,
            start: 0,
            end: 50, //初始化滚动条
          },
        ],
      },
      // 折线图配置
      brokeOption: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: '10%',
          // left: "12%",
          // right: "12%",
          // bottom: "20%",
        },
        grid: {
          show: true,
          top: "20%",
          left: "12%",
          right: "12%",
          bottom: "20%",
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            left: "9%",
            height: 15,
            realtime: true,
            bottom: 15,
            start: 0,
            end: 50, //初始化滚动条
          },
        ],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            name: '班级平均',
            type: "line",
            stack: 'Total',
          },
          {
            data: [],
            name: '个人平均',
            type: "line",
            stack: 'Total',
            // showSymbol: false,
            // areaStyle: {
            //   color: {
            //     type: "linear",
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            // colorStops: [
            //   {
            //     offset: 0,
            //     color: "#b8d0ff", // 0% 处的颜色
            //   },
            //   {
            //     offset: 1,
            //     color: "#f0f5ff", // 100% 处的颜色
            //   },
            // ],
            // globalCoord: false, // 缺省为 false
            // },
            // },
          },
        ],

      },
    })
    //  根据家长手机号拉孩子
    const parentSelectFun = () => {
      parentselect({
        jzsjh: JSON.parse(sessionStorage.getItem("userInfo")).yhm,
        yycdid: store.state.yycdid,
      }).then((res) => {
        if (res.success) {
          data.bjlist = res.result;
          // 孩子没有临时班，jzobj存放学生姓名
          data.jzobj = data.bjlist[0];
          // 返回的数组长度大于1获取去重后学生id数组
          if (res.result.length > 1) {
            data.parentbjid = data.bjlist[0].bjid;
            var setArr = [];
            data.bjlist.forEach((item) => {
              // 拿到所有学生id并去重
              setArr.push(item.xsid);
            });
            setArr = Array.from(new Set(setArr));
            // 获取根据学生id去重后的接口返回值数组
            setArr.forEach((item) => {
              data.bjSetlist.push(data.bjlist.find((ele) => ele.xsid == item));
            });
            // 孩子有临时班，更新jzobj
            data.jzobj = data.bjlist[0];
            data.xsList = data.bjlist.filter((item) => {
              return item.xsid == data.jzobj.xsid;
            });
            // 根据班级id拉集训
          }
          getjxList();
        }
      });
    };
    // 选中孩子
    const choice = (val) => {
      setTimeout(() => {
        data.flag = false;
      }, 500);
      // 点击学生时判断这个学生有没有多个班级，如果有，出现一个选择班级select
      data.xsList = data.bjlist.filter((item) => {
        return item.xsid == val.xsid;
      });
      data.parentbjid = data.xsList[0].bjid
      data.jzobj = val;
      getjxList();
    };
    // 更换孩子
    const childrenClick = () => {
      data.flag = true;
    };
    // 根据班级id拉集训
    const getjxList = () => {
      jxselect({
        bjid: data.jzobj && data.jzobj.bjid,
        yycdid: store.state.yycdid,
      }).then((res) => {
        if (res.success) {
          data.jxList = res.result;
          console.log(data.jxList)
          if (data.jxList.length) {
            data.parentjxid = data.jxList[0] ? data.jxList[0].jxid : '';
            data.jxobj = data.jxList[0] ? data.jxList[0] : {};
            //  更新数据
            getEchartsdataInit();
          } else {
            data.parentjxid = ''
            data.jxobj = {}
          }
        }
      });
    };
    // 集训change
    const selectChange = (val) => {
      data.jxobj = data.jxList.find((item) => {
        return item.jxid == val;
      });
      getEchartsdataInit();
    };
    // 班级change
    const selectbjChange = (val, mc) => {
      data.jzobj = data.bjlist.find((item) => {
        return item.bjid == val && item.xsmc == mc;
      });
      getjxList();
    };
    const getEchartsdataInit = () => {
      getStudyAnalysisFun()//整体统计
      // this.setColumn();//柱状图
      setBroken();//折线图

    };
    // 学情分析柱状图和整体情况的数据
    const getStudyAnalysisFun = () => {
      const params = {
        bjid: data.jzobj && data.jzobj.bjid,
        endTime: data.jxobj.yxqz,
        jxid: data.parentjxid,
        startTime: data.jxobj.yxqq,
        xsid: data.jzobj.xsid,
      }
      parentXqfx(params).then((res) => {
        if (res.resultCode == 200) {
          data.currentData = res.result;
          setmeter();
          setColumn();
          setBroken();
        }
      })
    };
    // 仪表盘
    const setmeter = () => {
      gaugeEcharts = echarts.init(document.getElementById("meter"));
      data.gaugeOption.series[0].data[0].value =
        data.currentData.ztqk !== null ? (data.currentData.ztqk) : 0;
      if (data.gaugeOption.series[0].data[0].value >= 73) {
        data.ybpval = "优";
      } else if (
        data.gaugeOption.series[0].data[0].value <= 73 &&
        data.gaugeOption.series[0].data[0].value >= 27
      ) {
        data.ybpval = "良";
      } else {
        data.ybpval = "差";
      }
      setTimeout(() => {
        gaugeEcharts.setOption(data.gaugeOption);
        window.addEventListener("resize", function () {
          gaugeEcharts.resize();
        });
      }, 500);
    };
    // 学情分析 柱状图
    const setColumn = () => {
      categoryEcharts = echarts.init(document.getElementById("column"));
      const arr = []
      data.currentData.xjRespList.forEach((item, index) => {
        arr.push({
          product: item.dayTime,
          threeXjzs: item.threeXjzs,
          twoXjzs: item.twoXjzs,
          oneXjzs: item.oneXjzs,
          zeroXjzs: item.zeroXjzs,
        })
      })
      data.categoryOption.dataset.source = arr;
      console.log(arr.length)
      setTimeout(() => {
        categoryEcharts.setOption(data.categoryOption);
        window.addEventListener("resize", function () {
          categoryEcharts.resize();
        });
      }, 500);
    };
    // 进步曲线  折线图
    const setBroken = () => {
      const params = {
        bjid: data.jzobj && data.jzobj.bjid,
        endTime: data.jxobj.yxqz,
        jxid: data.parentjxid,
        startTime: data.jxobj.yxqq,
        xsid: data.jzobj.xsid,
      }
      parentJbqx(params).then((res) => {
        if (res.resultCode == 200) {
          const rqList = [];
          const classData = [];
          const personalData = [];
          // const classAverage = {};//班级平均
          // const personalAverage = {};//个人平均
          // const resultArr = [];
          res.result.forEach((item, index) => {
            rqList.push(item.dayTime)
            classData.push(item.bjpjps)
            personalData.push(item.xsps)
          })
          console.log(res.result)
          brokeEcharts = echarts.init(document.getElementById("broken"));
          data.brokeOption.xAxis.data = rqList;
          // data.brokeOption.xAxis.data = ['2021-02-03', '2021-03-01']
          data.brokeOption.series[0].data = classData;
          // data.brokeOption.series[0].data = [3, 5];
          data.brokeOption.series[0].name = '班级平均'
          data.brokeOption.series[1].data = personalData
          // data.brokeOption.series[1].data = [3, 9];
          data.brokeOption.series[1].name = data.jzobj.xsmc;
          console.log(data.brokeOption.series)
          setTimeout(() => {
            brokeEcharts.setOption(data.brokeOption);
            window.addEventListener("resize", function () {
              brokeEcharts.resize();
            });
          }, 500);
        }
      })
    };
    // 确认退出
    const confirm = () => {
      router.replace({ name: "login" });
    };
    const loginout = () => {
      data.loginoutShow = true;
    };
    onMounted(() => {
      parentSelectFun()
    });
    const yxqfw = computed(() => {
      if (data.jxobj.yxqq) {
        return (
          proxy.$utils.getNowTime(0, 0, 0, data.jxobj.yxqq) + ' - ' + proxy.$utils.getNowTime(0, 0, -1, data.jxobj.yxqz)
        );
      } else {
        return "暂无时间";
      }
    });
    return {
      ...toRefs(data),
      yxqfw,
      parentSelectFun,
      choice,
      childrenClick,
      getjxList,//获取集训下拉
      selectChange,
      selectbjChange,
      getEchartsdataInit,//日期的禁止
      getStudyAnalysisFun,//集训的改变
      setmeter,//仪表盘
      setColumn,//柱状图
      setBroken,//折线图
      confirm,//确认框
      loginout,//退出登录

    }
  },
};
</script>
<style lang="scss" scoped>
.data-box {
  padding: 5vw;
  font-size: 18px;
  .tishi {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .nothing {
    font-size: 16px;
  }
}
img {
  display: block;
  width: 100%;
  height: 100%;
}
.mask {
  width: 30vh;
  height: 15vh;
  color: #000;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: rgba(89, 144, 255, .1);
  background: #fff;
  z-index: 9;
  .title {
    width: 100%;
    height: 30px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    background: #5990ff;
    border-radius: 10px 10px 0 0;
  }
  .maskbody {
    width: 80%;
    height: calc(80% - 30px);
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    box-sizing: border-box;
    color: #5990ff;
    .box {
      width: 40%;
      float: left;
      margin-left: 10px;
    }
    .box:nth-child(2n) {
      float: right;
    }
    .box:hover {
      transform: scale(1.2);
    }
  }
}
.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
}
.rope {
  height: 15px;
  width: 100%;
  position: absolute;
  bottom: -10px;
  padding: 0 18vw;
  div {
    position: relative;
    float: left;
    width: 8px;
    height: 28px;
  }
  div:nth-child(2) {
    float: right;
  }
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rightbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 15%;
  box-sizing: border-box;
  p:nth-child(1) {
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
  #meter {
    width: 100%;
    height: 85%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  p:nth-child(3) {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: #5990ffff;
    font-size: 18px;
  }
}
.parentEnd {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    background: #5990ff;
    box-sizing: border-box;
    padding: 3vw 2vw;
    overflow-y: auto;
  }
  .header {
    width: 100%;
    height: 30px;
    color: #fff;
    position: relative;
    i {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      font-weight: 600;
    }
    .out {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      font-size: 16px;
    }
  }
  .logo {
    width: 100%;
    height: 40vw;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .information {
    width: 100%;
    height: 90vw;
    background: #425de0;
    position: relative;
    padding: 0 2vw;
    box-sizing: border-box;
    .img {
      width: 50%;
      height: 11vw;
      position: absolute;
      top: 8vw;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      p {
        font-size: 4vw;
        color: #fff;
        span {
          font-size: 2vw;
        }
      }
    }
    .data {
      width: calc(100% - 4vw);
      height: 73vw;
      background: #fff;
      position: absolute;
      bottom: 4vw;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      .parenttable {
        position: absolute;
        top: 10vw;
        left: 0;
        right: 0;
        bottom: 20px;
        border-radius: 10px;
        overflow-y: auto;
      }
      .databox {
        width: 100%;
        height: 100%;
        padding: 10vw 2vw 5vw 2vw;
        .dataleft {
          width: 60%;
          height: 100%;
          float: left;
          div {
            height: 47%;
            position: relative;
            color: #fff;
            p:nth-child(2) {
              position: absolute;
              top: 20%;
              left: 8%;
              font-size: 13px;
            }
            p:nth-child(3) {
              position: absolute;
              top: 50%;
              left: 8%;
              font-size: 18px;
            }
          }
          div:nth-child(1) {
            margin-bottom: 6%;
          }
        }
        .dataright {
          position: relative;
          float: right;
          width: 38%;
          height: 100%;
        }
      }
    }
  }
  .select {
    width: 100%;
    height: 43vw;
    background: #425de0;
    position: relative;
    .data {
      width: calc(100% - 4vw);
      height: 37vw;
      background: #fff;
      position: absolute;
      bottom: 2vw;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      padding: 5vw;
      .text {
        font-size: 16px;
        font-weight: 600;
        p {
          float: left;
        }
        .btn {
          float: left;
          color: #409eff;
          font-size: 14px;
          margin: 2px 0 0 10px;
        }
      }
    }
    .selectbox {
      padding-top: 18vw;
      width: 100%;
      height: 100%;
      padding-left: 7vw;
      position: relative;
      pointer-events: none;
      p {
        padding: 3px;
        background: rgba(89, 144, 255, 0.6);
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
        position: absolute;
        bottom: 6vw;
      }
      /deep/.el-input__inner {
        height: 30px !important;
        line-height: 30px !important;
      }
    }
  }
}
#column {
  width: 100%;
  height: 100%;
}
#broken {
  width: 100%;
  height: 100%;
}
.el-table::before {
  height: 0;
}
</style>
<style lang="scss">
.van-dialog {
  width: 65vw;
}
.van-dialog__header {
  background: #409eff;
  width: 100%;
  padding: 0;
  height: 40px;
  font-size: 18px;
  color: #fff;
  line-height: 40px;
}
.van-dialog__content {
  width: 100%;
  height: 60px;
  padding: 20px;
  box-sizing: border-box;
  padding-left: 40px;
  span:nth-child(1) {
    display: block;
    width: 24px;
    height: 24px;
    float: left;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  span:nth-child(2) {
    display: block;
    float: left;
    margin-left: 3vw;
    font-size: 18px;
    color: #333;
  }
}
.van-dialog__footer {
  display: block;
  button {
    float: left;
    width: 50%;
  }
}
</style>